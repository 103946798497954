.loaderdiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #C6974B;

    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}


.loaderdiv1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.pointer-cursor {
    cursor: pointer;
}

.disabledContent {
    cursor: not-allowed !important;
    /* background-color: rgb(229, 229, 229) !important; */
}

.disabledContent>* {
    pointer-events: none;
}
.react-player {
  position: relative;
  top: 0;
  left: 0;
}

.w-80{
    width: 80px !important;
}

.sold_out {
    bottom: 2em;
    right: -4em;
    color: #fff;
    display: block;
    position:absolute;
    text-align: center;
    text-decoration: none;
    /* letter-spacing: .02em; */
    background-color: #A00;
    padding: 0.5em 5em 0.4em 5em;
    text-shadow: 0 0 0.75em #444;
    box-shadow: 0 0 0.5em rgba(0,0,0,0.5);
    font: bold 16px/1.2em Arial, Sans-Serif;
    -webkit-text-shadow: 0 0 0.75em #444;
    -webkit-box-shadow: 0 0 0.5em rgba(0,0,0,0.5);
    -webkit-transform: rotate(-45deg) scale(0.75,1);
    z-index:10;
}
.sold_out:before {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    margin: -0.3em -5em;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    border: 2px rgba(255,255,255,0.7) dashed;
}

.w-50{
    width: 50%;
}