body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', sans-serif;

}
.navbar-dark {
  --bs-navbar-color: #CEAD6E !important;
}
.navbar-nav>li{
  border-right: 1px solid #CEAD6E;
}
/* .navbar-nav>li:last-child{
  border: none;
} */

.navbar-nav {
    --bs-nav-link-padding-x: 0;
   --bs-nav-link-padding-y: 0 !important;
}

.error-msg{
      padding-left: 10px;
    text-align: left;
}
.is-invalid{
  border-color: #dc3545 !important;
}


.avatar-upload {
  position: relative;
  max-width: 205px;
 
}
.avatar-upload .avatar-edit {
  position: absolute;
  left: 0%;
  bottom:0px;
  z-index: 1;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 28px;
  height: 28px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #FFFFFF;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}
.avatar-upload .avatar-edit input + label:after {
  content: "\f040";
  font-family: 'FontAwesome';
  color: #757575;
  position: absolute;
  top: 4px;
  left: 3px;
  right: 0;
  text-align: center;
  margin: auto;
}
.avatar-upload .avatar-preview {
  width: 100px;
  height: 100px;
  position: relative;
  border-radius: 100%;
  
}
.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}