:root {
    --color-primary: #72a569;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.contain {
    object-fit: contain;
}
.static-title,
.transform-none {
    transform: none !important;
}
.static-title {
    left: auto;
    bottom: auto;
}
.cover {
    object-fit: cover !important;
}
.mb24 {
    margin-bottom: 24px;
}
/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
.c_select {
    width: 220px;
    background-color: #ffffff;
    color: #7f7f7f;
    box-shadow: none;
    outline: none !important;
    border-color: #ddd;
    border-radius: 4px;
    height: 50px;
    line-height: 35px;
}
.form-select {
    &:focus {
        border-color: #ddd;
    }
}
.btn-booking {
    background: var(--color-primary);
    color: #fff;
    padding: 10px 30px;
    font-size: 19px;
    display: inline-flex;
    border-radius: 4px;
    font-weight: 600;
    &:hover {
        background: #fff;
        color: var(--color-primary);
    }
}
select {
    border: none;
    &:focus {
        outline: none;
    }
}
.home1-advnc-search {
    position: relative;
    display: flex;
    justify-content: center;
}
.stricky-fixed {
    animation: slide-down 0.7s ease-in-out;
}
a {
    transition: 0.3s;
}

.home1_bgi1 {
    @media (min-width: 1500px) {
        height: 100vh;
    }
    .home_content {
        @media (min-width: 1500px) and (max-width: 1800px) {
            padding: 220px 0 400px;
        }
    }
}
@keyframes slide-down {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

h4 {
    transition: 0.3s;
}
.custom-checkbox {
    .form-check-input,
    .form-check-label {
        cursor: pointer !important;
    }
}
.header-nav {
    left: 0;
    padding: 30px 70px;
    position: fixed;
    right: 0;
    top: 0;
    transition: all 0.4s ease-out;
    z-index: 99;
    background: transparent;
}
.sign_up_modal .modal-header .btn-close {
    margin: 0;
    filter: invert(1);
    opacity: 1;
}
.form-check-input:focus,
.sign_up_modal .modal-header .btn-close,
.form-select {
    &:focus {
        box-shadow: none;
    }
}
.home1-advnc-search ul li .search_option_two {
    width: 180px;
    @media (max-width: 520px) {
        width: 100%;
    }
}

.container {
    max-width: 1170px;
}
.container-fluid,
.container {
    padding-left: 15px;
    padding-right: 15px;
}

.home_adv_srch_opt .nav-link {
    transition: none;
}

.ui_kit_checkbox.selectable-list li {
    line-height: 1.7;
    margin-right: 23px !important;
    margin-bottom: 15px;
}
.form-check-input:checked {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}
.apeartment_area_list {
    display: flex;
    align-items: center;
    @media (max-width: 575px) {
        flex-direction: column;
    }
    li {
        @media (min-width: 768px) {
            width: 25% !important;
        }

        @media (max-width: 575px) {
            margin-bottom: 10px;
        }
    }
}
.sign_up_modal :not(.btn-check) + .btn:hover,
.sign_up_modal .btn:first-child:hover {
    color: var(--color-primary);
    background-color: #ffffff;
    border-color: var(--color-primary);
}
.custome_fields_520 .dropdown-content:before,
.dd_content:before,
.dd_content2:before {
    display: none;
}

.copyright-widget a {
    color: white;
    &:hover {
        color: var(--color-primary) !important;
    }
}
ol,
ul {
    padding-left: 0;
}

// sidebar menu
.offcanvas {
    max-width: 300px;
    .pro-sidebar {
        width: 300px;
        min-width: 300px;
    }
    &.offcanvas-dashboard {
        max-width: 295px;
        transform: translateX(0);
        visibility: visible;
        border-right: none;
        background: transparent;
        @media (max-width: 1024px) {
            max-width: 250px;
            transform: translateX(-100%);
            visibility: hidden;
        }
        &.show {
            transform: translateX(0);
            visibility: visible;
        }
    }
}
.our-dashbord.dashbord {
    @media (max-width: 1024px) {
        margin-top: 0;
        padding-left: 0;
    }
}
.sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background: #fff;
    .fix-icon {
        font-size: 18px;
        color: #333;
        transition: 0.3s;
        cursor: pointer;
        &:hover {
            color: #222;
        }
    }
}
.sidebar-header-inner {
    display: flex;
    align-items: center;
    img {
        margin: 0;
    }
    .brand-text {
        color: #333;
        font-weight: bold;
        font-size: 22px;
        padding-left: 15px;
        text-transform: capitalize;
        vertical-align: middle;
    }
}
.pro-sidebar-footer {
    margin: 50px auto;
    width: 90%;
    display: flex;
    align-items: center;
    border: none !important;
    .btn {
        width: 100%;
    }
}
.pro-sidebar .pro-menu .pro-menu-item {
    font-size: 16px !important;
}
.pro-sidebar .pro-menu > ul > li {
    &:not(:last-child) {
        border-bottom: 1px solid rgba(173, 173, 173, 0.2);
    }
}
.pro-sidebar .pro-menu > ul > .pro-menu-item > .pro-inner-item {
    padding: 12px 35px 12px 20px !important;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item,
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item a {
    transition: 0.3s;
}

.pro-sidebar {
    .btn-thm:hover {
        border-color: var(--color-primary);
    }
}

.pro-sidebar > .pro-sidebar-inner {
    background: #f5f5f5 !important;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background-color: #eee !important;
}

$sidebar-color: #333;

.pro-sidebar,
.pro-sidebar .pro-menu a {
    color: $sidebar-color !important;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover,
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
    color: #333 !important;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
    padding: 3px !important;
}

.pro-sub-menu.open > .pro-inner-item {
    > .pro-item-content {
        color: var(--color-primary) !important;
    }
}

.list_s .flaticon-user:before {
    padding-right: 5px;
}

// map
.gmap_canvas {
    height: 600px;
    background: #eee;
    position: relative;
    @media (min-width: 1280px) and (max-width: 1700px) {
        height: 554px;
    }
    @media (max-width: 767px) {
        height: 400px;
    }

    .location-finder {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 50px;
        z-index: 2;
    }
    iframe {
        width: 100%;
        height: 100%;
    }
}

// header custom css
.home-3-wrapper {
    padding-left: 15px;
    padding-right: 15px;
    .header-nav {
        &.home3 {
            max-width: 1600px;
            margin: 0 auto;
            padding: 0 15px;
        }
        .ace-responsive-menu {
            .add_listing {
                background-color: rgb(36, 50, 74) !important;
            }
        }
    }
}

.mt-89 {
    margin-top: 89px;
}
.main-title p a {
    &:hover {
        color: var(--color-primary);
    }
    > span {
        font-size: 13px;
    }
}
.feat_property .thumb .thmb_cntnt .icon li {
    transition: 0.3s;
    &:hover {
        background-color: var(--color-primary);
        opacity: 1;
    }
}
header.header-nav.menu_style_home_one .ace-responsive-menu li.add_listing {
    border: 2px solid transparent;
    transition: 0.3s ease-in-out;
}
header.header-nav.menu_style_home_one .ace-responsive-menu li.add_listing:hover {
    background-color: #fff !important;
    border-color: var(--color-primary);
    a {
        color: var(--color-primary) !important;
    }
}
.stricky-fixed {
    &.header-nav.menu_style_home_one a.navbar_brand {
        margin-top: 17px;
    }
    &.header-nav.menu_style_home_one .ace-responsive-menu > li > a {
        padding-top: 30px;
    }
}

// home 5 advance search
.home_content.home5 {
    position: relative;
    .dropdown-content.dropdown-menu {
        width: 644px;
        @media (max-width: 767px) {
            width: 100%;
        }
    }
}
.home-text.home5 {
    @media (max-width: 576px) {
        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin: -5px;

            li {
                flex: 0 0 50%;
                padding: 5px;
                margin: 0;
            }
            .icon_home5 {
                width: 100%;
            }
        }
    }
    .icon_home5 {
        transition: 0.3s;
        &:hover {
            background-color: rgba(255, 255, 255, 0.249);
        }
    }
}
.home5_bgi5 {
    @media (max-width: 767px) {
        height: auto;
    }
}
.home5_advanced_search_form {
    > ul {
        display: flex;
        flex-direction: column;

        li {
            display: block;
            margin-bottom: 20px;

            .form-control,
            .search_option_two,
            .small_dropdown2 .dd_btn,
            .btn.btn-thm {
                width: 100% !important;
            }
            .btn.btn-thm {
                background-image: linear-gradient(130deg, #72a569 10%, #8cb169 25%,#799dae 61%, #a8bc68 81%,  #e6cf60 100%, );
                &:hover {
                    border: none;
                    color: #fff;
                }
            }

            .mega-dropdown {
                border: 1px solid #ebebeb;
                border-radius: 8px;
                background-color: #ffffff;
                font-size: 14px;
                height: 50px;
                padding: 0.375rem 0.75rem;

                .dropbtn {
                    display: block;
                    margin-top: -3px;
                    position: relative;

                    .flaticon-more {
                        position: absolute;
                        right: 0;
                    }
                }
            }
        }
    }
    .apeartment_area_list {
        flex-wrap: wrap;
        li {
            width: 46%;
            max-width: 100%;
        }
    }
}

// why choose
.why_chose_us.style2 {
    &::after {
        background-color: var(--color-primary);
        bottom: 0;
        content: "";
        height: 5px;
        left: 0;
        position: absolute;
        right: 0;
        width: 100%;
        transition: 0.3s ease-in-out;
        opacity: 0;
        visibility: hidden;
    }
    .icon {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            background-image:linear-gradient(130deg, #72a569 10%, #8cb169 25%,#799dae 61%, #a8bc68 81%,  #e6cf60 100%, );
            transition: 0.3s ease-in-out;
            opacity: 0;
            visibility: hidden;
        }
        span {
            position: relative;
            z-index: 2;
            transition: 0.3s ease-in-out;
        }
    }
    &:hover {
        .icon {
            &::after {
                visibility: visible;
                opacity: 1;
            }
        }
        &:after {
            visibility: visible;
            opacity: 1;
        }
    }
}

// home6 nav
.home6-nav {
    a.navbar_brand,
    .ht_left_widget {
        margin-top: 0 !important;
    }
    ul.ace-responsive-menu li.add_listing {
        top: 0 !important;
    }
}
.feature_property_home6_slider,
.our_agents_home6_slider {
    margin-left: calc((100% - 1170px) / 2);
    overflow: hidden;

    @media (max-width: 1349px) {
        margin-left: auto;
    }

    .slick-list {
        overflow: visible;
    }
    // @media (max-width: 1600px) {
    //     max-width: 1346px;
    // }
    // @media (max-width: 1400px) {
    //     max-width: 1246px;
    // }
    .slick-prev {
        left: -18px;
    }
    .slick-next {
        right: -18px;
    }
}

@media only screen and (min-width: 1350px) {
    .ml--xxl-0 {
        margin-left: 0;
    }
}
.home_adv_srch_opt.home7 {
    .btn.btn-thm {
        &:hover {
            color: #fff;
        }
    }
}

// testimonials

.testimonial_grid_slider.style2 {
    .slick-dots li.slick-active button {
        background-color: #24324a !important;
        border: 5px solid #dedede;
    }
    .slick-dots li button {
        width: 3px;
        height: 3px;
        padding: 3px;
    }
}

// header
.header-nav.home8 {
    .ace-responsive-menu.text-end {
        text-align: left !important;
    }
    .add_listing {
        top: 27px;
    }
}
.small_dropdown2 .dd_btn:hover {
    border-color: #ebebeb;
}

//
.slider-wrapper {
    width: 760px;
    margin: auto;
}
.slick-slide {
    position: relative;
}
.slick-slide:focus {
    outline: none;
}
.slick-slide-title {
    text-transform: capitalize;
}
.slick-slide-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
        0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
}
.slick-slide-label {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0px;
    font-size: 1.5em;
    bottom: 0px;
    width: 100%;
}
.slick-prev:before,
.slick-next:before {
    color: #777777;
}

// slick animatin
@keyframes customOne {
    from {
        opacity: 0;
        transform: translateY(40px);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.customOne {
    -webkit-animation-name: customOne;
    animation-name: customOne;
}

// slick as nav
.slick-custom-as-nav {
    .slick-slide {
        img {
            transition: 0.3s;
            transform: scale(0.6);
            opacity: 0.6;
        }
        &.slick-center {
            img {
                transform: scale(1);
                opacity: 1;
            }
        }
        .testimonial_item {
            margin-top: 20px;
        }
    }
    .item {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }
}

/* hero 8 */
.home8-slider {
    .slick-dots {
        position: absolute;
        bottom: 50px;
        @media (max-width: 360px) {
            bottom: 40px;
        }
        li.slick-active button {
            background-color: #fff !important;
            border-color: #666;
        }
    }
    .bs_carousel_bg {
        -webkit-background-size: cover;
        background-size: cover;
        position: relative;
    }

    .bs_carousel .bs_carousel_bg:after {
        background-color: rgba(29, 41, 62, 0.6);
        bottom: 0;
        content: " ";
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }

    .bs_carousel_prices .carousel-slide:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.1);
        transform: scale(0, 1);
        transform-origin: 0% 50%;
        -webkit-transition: all 6.4s linear;
        -o-transition: all 6.4s linear;
        transition: all 6.4s linear;
    }

    .bs_carousel .carousel-slide .bs-caption {
        color: #ffffff;
        left: 0;
        position: absolute;
        right: 0;
        top: 54%;
        transform: translateY(calc(-50% - 30px));
        z-index: 2;
        opacity: 0;
        transition: 0.3s;
        @media (min-width: 1280px) and (max-width: 1700px) {
            top: 58%;
        }
    }

    .bs_carousel .main_title {
        color: #ffffff;
        font-family: "Nunito";
        font-size: 55px;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 15px;
        transition: all 0.6s ease-in-out;
    }

    .bs_carousel .parag {
        font-size: 18px;
        font-family: "Nunito";
        color: #ffffff;
        line-height: 1.2;
        margin-bottom: 0;
        transition: all 0.9s ease-in-out;
    }

    .bs_carousel {
        .slick-slide {
            &.slick-current.slick-active {
                .bs-caption {
                    opacity: 1;
                }
            }
        }
    }
}

header.header-nav.menu_style_home_one.style2.home10 ul.ace-responsive-menu li.add_listing {
    border-radius: 8px;
    background-color: rgb(53, 71, 101);
    box-shadow: none;
    top: 18px;
    &:hover {
        border: none;
    }
  
      
  
}

// testimonial
.testimonial_slider_home9.gutter-x15 {
    .slick-list {
        margin: 0 -15px;
    }
    .slick-slide {
        padding: 0 15px;
    }
}

// header 10
.header-top-home10 {
    display: flex;
    align-items: center;
}

.main-banner-wrapper.home10 {
    .prop_details {
        margin-top: 10px;
        margin-bottom: 10px;
        li a {
            color: #fff;
        }
    }
}

// slider animation

.home8-slider .slick-slide {
    &.slick-current {
        .bs-caption {
            .main_title {
                animation: 800ms ease-in-out 0.2s normal none 1 running customOne;
            }
            .parag {
                animation: 900ms ease-in-out 0.2s normal none 1 running customOne;
            }
            .btn-booking {
                animation: 1000ms ease-in-out 0.2s normal none 1 running customOne;
            }
            .feat_property.home8 {
                animation: 900ms ease-in-out 0.2s normal none 1 running customOne;
            }
        }
    }
}

.home10-banner-style .slick-slide {
    &.slick-current {
        .banner_top_title {
            animation: 700ms ease-in-out 0.2s normal none 1 running customOne;
        }
        .banner-title {
            animation: 800ms ease-in-out 0.2s normal none 1 running customOne;
        }
        .prop_details {
            animation: 900ms ease-in-out 0.2s normal none 1 running customOne;
        }
        .active .banner-btn {
            animation: 1000ms ease-in-out 0.2s normal none 1 running customOne;
        }
    }
}

// form
textarea.form-control {
    height: auto;
}

// accordion
.faq_according .accordion .card .card-header .btn-link {
    text-decoration: none;
}

// error page
.error_page,
.blog_post_container {
    margin-top: 85px;
    @media (max-width: 991px) {
        margin-top: 0;
    }
    .img-thumb {
        max-height: 400px;
        @media (max-width: 1600px) {
            max-height: 200px;
        }
    }
}
.md-mt0 {
    @media (max-width: 991px) {
        margin-top: 0 !important;
    }
}
.grid_list_search_result {
    @media (max-width: 767px) {
        padding-bottom: 20px;
    }
}
// breadcrumb
.style2 .breadcrumb-item.active {
    color: var(--color-primary) !important;
}
.breadcrumb_title {
    margin-bottom: 0;
    margin-top: 10px;
}

.feat_property .details .fp_footer .fp_meta li a {
    display: inline-flex;
}

//
.mbp_thumb_post li a {
    color: #555;
}

.feat_property .details .tc_content h4:hover a,
.feat_property .details .tc_content .h4:hover a {
    color: var(--color-primary);
}

.feat_property .details .fp_footer .fp_pdate {
    .flaticon-next::before {
        font-size: 12px;
    }
}

// sidebar widget css
.sidebar_advanced_search_widget {
    .accordion-toggle.link[aria-expanded="true"],
    .accordion-toggle.link[aria-expanded="true"] i {
        color: var(--color-primary) !important;
    }
    .sasw_list .form-select {
        background-color: #ffffff;
        border: 1px solid rgb(235, 235, 235);
        border-radius: 8px;
        font-size: 14px;
        font-family: "Nunito";
        color: #484848;
        line-height: 1.2;
        height: 52px;
        padding-left: 20px;
    }
    .ui_kit_checkbox {
        display: flex;
        flex-wrap: wrap;
        margin: 0px -12px;

        li {
            padding: 0 12px;
            flex: 0 0 50%;
            max-width: 50%;
            font-size: 14px;
            margin-right: 0 !important;

            label {
                padding-left: 5px;
            }
        }
    }
    .sasw_list .search_area label {
        right: 10px;
    }
    .sasw_list .search_area input {
        padding-right: 40px;
    }
}

.sidebar_feature_property_slider .feat_property {
    margin-bottom: 0;
}

.offcanvas-listing-sidebar {
    .offcanvas-body {
        padding: 0;
    }
    &.offcanvas {
        max-width: 400px;
        @media (max-width: 767px) {
            max-width: 320px;
        }
    }
}

.sidebar-listing-wrapper {
    .sidebar_listing_grid1 {
        @media (max-width: 991px) {
            display: none;
        }
    }
}

//
.style_two-pro {
    .posr {
        position: absolute;
        bottom: 10px;
        left: 20px;
    }
    .feat_property {
        margin-bottom: 0;
        .thumb .thmb_cntnt a.fp_price {
            bottom: 50px;
        }
    }
}

@media (max-width: 1024px) {
    .db-1024 {
        display: block !important;
    }
}

.team_member,
.our_agent {
    .details {
        // h4 a {
        //     font-weight: 600;
        // }
    }
    &:hover {
        h4 a {
            color: var(--color-primary);
        }
    }
}

// offcanvas sidebar
.offcanvas-body {
    .sidebar_listing_list,
    .terms_condition_widget,
    .sidebar_recent_product,
    .sidebar_feature_listing {
        padding-left: 20px;
        padding-right: 20px;
        border: none;
    }
}

.shop_single_tab_content.style2 {
    .feat_property.list.style2 .thumb {
        height: 240px;
        @media (max-width: 991px) {
            height: 190px;
        }
        img {
            object-fit: cover;
            height: 100%;
        }
    }
}
.img-gallery-box img {
    height: 133px;
    object-fit: cover;
}
//
.lds-1 {
    height: 450px;
    @media (max-width: 1024px) {
        height: 390px;
    }
    @media (max-width: 991px) {
        height: 370px;
    }
    @media (max-width: 767px) {
        height: auto;
    }
}
.lds-3 {
    height: 250px; /* Default height for larger screens */
    
    @media (max-width: 1024px) {
        height: 190px; /* Height for medium screens */
    }
    
    @media (max-width: 991px) {
        height: 170px; /* Height for smaller screens */
    }
    
    @media (max-width: 767px) {
        height: auto; /* Responsive height for mobile devices */
    }
}
//
.lds-2 {
    height: 569px;
    @media (max-width: 1600px) {
        height: 456px;
    }
    @media (max-width: 1024px) {
        height: 307px;
    }
    @media (max-width: 991px) {
        height: 230px;
    }
    @media (max-width: 767px) {
        height: auto;
    }
}

//
.properti_city.home6 img {
    height: 370px;
    object-fit: cover;
}

.feature-list {
    .thumb {
        flex: 0 0 45%;
    }
    .details {
        flex: 1;
    }
}

.ui_kit_checkbox.selectable-list > li {
    display: block;
}

.mobile-filter-menu {
    @media (max-width: 991px) {
        left: 10px;
    }
    #main2 .filter_open_btn {
        @media (max-width: 991px) {
            position: static;
            margin-bottom: 20px;
            display: flex;
        }
    }
}

.home_adv_srch_opt.home7 .h1ads_1st_list {
    @media (max-width: 576px) {
        display: flex;
        flex-direction: column;
        .search_option_button {
            margin-top: 20px;
        }
    }
}

.main-title .float-end {
    @media (max-width: 576px) {
        margin-top: 20px;
    }
}

.blog_sp_post_meta li a img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.listing_single_property_slider {
    &.arrow-style-2 {
        height: 544px;
        @media (max-width: 1100px) {
            height: 297px;
        }
        @media (max-width: 991px) {
            height: 338px;
        }
    }
    .item img {
        width: 100%;
    }
}
.arrow-style-2.arrow-white .slick-arrow {
    background: #fff;
    &:hover {
        background: #efefef;
    }
    &::before {
        color: #222;
    }
}

.home-listing-slider.hight-fx {
    height: 721px;
    @media (max-width: 991px) {
        height: auto;
    }
    @media (max-width: 767px) {
        padding: 0;
    }
}

.home_iconbox_container.listing-slider-style {
    @media (max-width: 1199px) {
        top: 44%;
    }
    @media (max-width: 991px) {
        top: 10%;
    }
    @media (max-width: 767px) {
        top: -13%;
    }
}

.listing-slider-style .home_adv_srch_opt {
    @media (max-width: 767px) {
        margin-top: 40px;
    }
}

.half_map_area {
    min-height: 100vh;
    position: relative !important;
    @media (max-width: 1199px) {
        min-height: auto;
    }
}
.map-half-style {
    .form-select {
        height: 50px;
        border-color: rgb(235, 235, 235);
    }
}

@media (max-width: 767px) {
    .grid_list_search_result.md-flex {
        display: flex;
        justify-content: center;
    }
    .half_map_area_content {
        margin-top: 30px;
    }
}
.listing-map-style ul.mb-3 {
    margin-bottom: 0 !important;
}

.list_map .feat_property.home7.style4 .thumb {
    max-height: 285px;
    flex: 0 0 35%;
}

@media (max-width: 991px) {
    .sidebar_switch #main2 .filter_open_btn.style2 {
        top: 0px;
    }
}

.ui_kit_2 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li {
        flex: 0 0 20%;
        max-width: 20%;
    }
}
.apeartment_area_list.style-2 {
    margin: 20px -10px;
    li {
        padding: 0 10px;
    }
}

.filter-let-top {
    #main2 .filter_open_btn {
        top: 85px;
        left: 40px;
    }
}

@media (min-width: 1200px) and (max-width: 1700px) {
    .our-listing_map2 {
        .col-xxl-5,
        .col-xxl-7 {
            width: 50%;
        }
    }
    .check-desktop {
        .col-xl-3 {
            width: 30%;
        }
        .col-xl-9 {
            width: 70%;
        }
    }
}

@media (min-width: 1200px) {
    .grid_list_search_result .topbar-2 .right_area ul li:first-child {
        margin-right: 0;
    }
}

.user_setting {
    .btn {
        border: none !important;
    }
}

.sidebar_header {
    display: flex;
    align-items: center;
    a {
        width: 100%;
        height: 100%;
        color: #fff;
        padding: 0;
        position: static;
        display: flex;
        align-items: center;

        &::after,
        &::before {
            display: none;
        }

        span {
            display: inline-block;
            background: transparent;
            height: auto;
            padding-left: 0 !important;
            width: auto;
        }
    }
}

.treeview a[aria-expanded="true"] {
    border-left-color: var(--color-primary) !important;
    color: #fff !important;
}
.user_setting_content a:hover {
    color: var(--color-primary) !important;
}

.my_dashboard_review {
    .media {
        display: flex;
        align-items: flex-start;
        img {
            margin-right: 1rem;
        }
    }
    .media-body {
        flex: 1;
    }
}

.client-review .media:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
}
