/* @keyframes color {
    0%   { background: #72a569; }
    20%  { background: #8cb169; }
    40%  { background: #a8bc68; }
    60%  { background: #a8bc68; }
    80%  { background: #e6cf60; }
    100% { background: #72a569; }
  }
  
  .theme-background {
    background: #72a569; 
    animation: color 9s infinite linear;
    text-align: center;
    padding: 2em;
  } */


.bg-theme {
  width: inherit;
  height: inherit;


  background-image: linear-gradient(130deg, #72a569 10%, #8cb169 25%, #799dae 61%, #a8bc68 81%, #e6cf60 100%, );
  -webkit-animation: AnimateBG 20s ease infinite;
  animation: AnimateBG 20s ease infinite;
}

@-webkit-keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}



.card-about-us {
  border-radius: 50%;
  border: 0;
  width: 360px;
  height: 360px;
  background: url('../../../public/assets/images/about/1.jpg');
  position: relative;
  overflow: hidden;
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.85);
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.85) inset, inset 0 0 0 16px rgba(0, 0, 0, 0.85);
  margin: 10px;

}

.card-about-us:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-image: linear-gradient(130deg, #72a569 10%, #8cb169 25%, #799dae 61%, #a8bc68 81%, #e6cf60 100%, );
  -webkit-animation: AnimateBG 20s ease infinite;
  animation: AnimateBG 20s ease infinite;
}

.card-about-us .img,
.card-about-us .img-mission,
.card-about-us .img-vision,
.card-about-us .img-culture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}



.card-about-us .img span {
  width: 100%;
  height: auto;
  background: url('../../../public/assets/images/about/1.jpg');
  transition: 0.5s;
  background-size: cover;
}

.card-about-us .img-vision span {
  width: 100%;
  height: auto;
  background: url('../../../public/assets/images/about/vision3.png');
  transition: 0.5s;
  background-size: cover;
}

.card-about-us .img-mission span {
  width: 100%;
  height: auto;
  background: url('../../../public/assets/images/about/mission3.png');
  transition: 0.5s;
  background-size: cover;
}

.card-about-us .img-culture span {
  width: 100%;
  height: auto;
  background: url('../../../public/assets/images/about/culture3.png');
  transition: 0.5s;
  background-size: cover;
}

.card-about-us .img span:nth-child(1),
.card-about-us .img-mission span:nth-child(1),
.card-about-us .img-vision span:nth-child(1),
.card-about-us .img-culture span:nth-child(1) {
  background-position: 0;
  transition-delay: 0;
}

.card-about-us .img span:nth-child(2),
.card-about-us .img-mission span:nth-child(2),
.card-about-us .img-vision span:nth-child(2),
.card-about-us .img-culture span:nth-child(2) {
  background-position: 33.33333%;
  transition-delay: 0.1s;
}

.card-about-us .img span:nth-child(3),
.card-about-us .img-mission span:nth-child(3),
.card-about-us .img-vision span:nth-child(3),
.card-about-us .img-culture span:nth-child(3) {
  background-position: 66.66666%;
  transition-delay: 0.2s;
}

.card-about-us .img span:nth-child(4),
.card-about-us .img-mission span:nth-child(4),
.card-about-us .img-vision span:nth-child(4),
.card-about-us .img-culture span:nth-child(4) {
  background-position: 100%;
  transition-delay: 0.3s;
}

.card-about-us:hover .img>span,
.card-about-us:hover .img-mission>span,
.card-about-us:hover .img-vision>span,
.card-about-us:hover .img-culture>span {
  transform: translateY(-100%);
}

.card-about-us:hover .content {
  transform: translateY(0%);
  transition: 1s;
  transition-delay: 0.1s;
}

.content {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  padding: 20px 20px;
  width: 100%;
  height: 100%;
  transform: translateY(100%);
}



/* section divider content */
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700');


.row-divider {
  width: 100%;
  /* height:80vh; */
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

/* -- SVG CSS -- */

.svg-separator {
  display: block;
  background: 0 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
  -webkit-transform: translateY(-100%) translateY(2px);
  transform: translateY(-100%) translateY(2px);
  width: 100%;
}

.svg-separator.bottom {
  top: auto;
  bottom: 0;
}

.sep1 {
  transform: translateY(-100%) translateY(2px) scale(1, 1);
  transform-origin: top;
}

.sep2 {
  transform: translateY(-100%) translateY(2px) scale(1, 1);
  transform-origin: top;
}

.sep3 {
  transform: translateY(-100%) translateY(2px) scale(1, 1);
  transform-origin: top;
}

.sep4 {
  transform: translateY(-100%) translateY(2px) scale(1, 1);
  transform-origin: top;
}

.sep5 {
  transform: translateY(-100%) translateY(2px) scale(1, 1);
  transform-origin: top;
}

.sep6 {
  transform: translateY(-100%) translateY(2px) scale(1, 1);
  transform-origin: top;
}

.sep7 {
  transform: translateY(-100%) translateY(2px) scale(1, 1);
  transform-origin: top;
}

.sep8 {
  transform: translateY(-100%) translateY(2px) scale(1, 1);
  transform-origin: top;
}

.sep9 {
  transform: translateY(0%) translateY(-2px) scale(-1, -1);
  transform-origin: bottom;
}

.sep10 {
  transform: translateY(-100%) translateY(2px) scale(1, 1);
  transform-origin: top;
}

.sep11 {
  transform: translateY(-100%) translateY(2px) scale(1, 1);
  transform-origin: top;
}

.sep12 {
  transform: translateY(-100%) translateY(2px) scale(1, 1);
  transform-origin: top;
}

/* -- Row BG Color -- */

.green {
  background-color: #72a569;
}

.green2 {
  background-color: #1bbc9b;
}

.red {
  background-color: #d24d57;
}

.light-grey {
  background-color: #ecf0f1;
}

.light-grey2 {
  background-color: #ecf0f1;
}

.dark-blue {
  background-color: #22313f;
}

.grey-blue {
  background-color: #6c7a89;
}

.black {
  background-color: #333;
}

.stupid-blue {
  background-color: #19b5fe;
}

/* Farms to Fortune */
.farm2forrtune {
  background: linear-gradient(130deg, #72a569 10%, #8cb169 25%, #799dae 61%, #a8bc68 81%, #e6cf60 100%);
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block;
  font-size: 45px;
  font-family: "Nunito";
  font-weight: bold;
  line-height: 1.2;
  text-transform: capitalize;
}

.carousel-container {
  width: 100%;
  /* Set your desired width */
  height: auto;
  /* Set your desired height */
  position: relative;
}

.otp-input-container {
  display: flex;
}

.otp-input-container .otp-input {
  width: 40px;
  /* Adjust the width of each input box */
  height: 40px;
  /* Adjust the height of each input box */
  margin-right: 10px;
  /* Adjust the right margin to add space between input boxes */
}

/* Style the last input box to remove the right margin */
.otp-input-container .otp-input:last-child {
  margin-right: 0;
}

/* Slick dots */
.slick-dotss {
  margin: 0 0 10px !important;
  padding: 0;
  display: block;
  text-align: center;
  line-height: 2;
  padding-bottom: 10px;
}

.slick-dotss li {
  display: inline-block;
  list-style: none;
  display: inline-block;
  font-size: 0;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  margin-right: 10px;
  background-color: transparent;
  cursor: pointer;
  margin-top: 0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.slick-dotss li button {
  display: none;
}

.slick-dotss li:hover,
.slick-dotss li.slick-active {
  background-color: transparent;
  height: 95px;
  width: 95px;
  margin-bottom: -122px;

}

/* Know Your User sectioj styling */
.tathmeer-section {
  position: relative;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 20px; */
  overflow: auto;

}

.tathmeer-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* Make it twice as wide to cover the entire container */
  height: 100%;
  background-image: url('../images/logo-background-1.png');
  background-size: 100% cover;
  background-position: 495px;
  /* Adjust as needed, e.g., 'center left' */
  background-repeat: no-repeat;
  opacity: 0.2;
  /* Adjust the opacity for transparency */
  overflow: auto;
  z-index: -1;
}

.tathmeer-section-left {
  position: relative;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 20px; */
  overflow: auto;

}

.tathmeer-section-left::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* Make it twice as wide to cover the entire container */
  height: 100%;
  background-image: url('../images/logo-background-1.png');
  background-size: 100% cover;
  background-position: -75px;
  /* Adjust as needed, e.g., 'center left' */
  background-repeat: no-repeat;
  opacity: 0.2;
  /* Adjust the opacity for transparency */
  overflow: auto;
}

.tathmeer-section-plain {
  position: relative;
  width: 100vw;
  /* Set width to viewport width */
  height: 100vh;
  /* Set height to viewport height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* Hide any content that exceeds the dimensions */
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 0;
  /* Set a lower z-index for the background */

}

.tathmeer-section-plain::before,
.tathmeer-section-plain::after {
  content: '';
  position: absolute;
  width: 50%;
  /* Set width to cover the entire viewport */
  height: 100%;
  /* Set height to half of the viewport height */
  background-image: url('../images/logo-background-1.png');
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  /* Set a higher z-index for the background image */


}


.tathmeer-section-plain::before {
  top: -20vh;
  /* Move it up by 25% of the viewport height */
  left: -20vw;
  /* Move it left by 25% of the viewport width */
}

.tathmeer-section-plain::after {
  bottom: -10vh;
  /* Move it down by 25% of the viewport height */
  right: -20vw;
  /* Move it right by 25% of the viewport width */
}

@media (max-width: 768px) {

  .tathmeer-section-plain::before,
  .tathmeer-section-plain::after {
    height: 70%;
    /* Set a different height for smaller screens */

  }

  .tathmeer-section-plain::before {
    top: -25vh;
    /* Move it up by 25% of the viewport height */
    left: -10vw;
    /* Move it left by 25% of the viewport width */
  }

  .tathmeer-section-plain::after {
    bottom: -10vh;
    /* Move it down by 25% of the viewport height */
    right: -20vw;
    /* Move it right by 25% of the viewport width */
  }
}

.core-values-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* Make it twice as wide to cover the entire container */
  height: 100%;
  background-image: url('../images/background/coreValues.png');
  background-size: contain;
  background-position: center;
  /* Adjust as needed, e.g., 'center left' */
  background-repeat: no-repeat;
  overflow: auto;
}


.grid-activity {
  display: flex;
  align-items: center;
}

.list-inline-activity {
  display: flex;
  align-items: center;
}

.list-inline-item-activity {
  margin-right: 8px;
  /* Adjust as needed */
}

.icon-activity {
  display: flex;
  align-items: center;
}

.icon-activity span {
  margin-right: 5px;
  /* Adjust as needed */
}


/* Login.css */

.position-relative {
  position: absolute;
}

.rounded-lg-left {
  border-top-left-radius: 20px;
  /* Adjust as needed */
  border-bottom-left-radius: 20px;
  /* Add if needed for consistency */
  overflow: hidden;
  /* Ensure content does not overflow the rounded corners */
}

.shadow-lg {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  /* Adjust shadow as needed */
}

.col-special {
  box-shadow: 0px 5px 0px -5px  rgba(0, 0, 0, 0.5);
  clip-path: inset(0px -15px 0px 0px);
  border-radius: 5% 0% 0% 5%;
  background-color: #fff;
  margin-left: -40px;
  
}

@media (max-width: 768px) {
  .col-special {
    margin-left: 0;
  }
}

p.noteAlt
{
     background-color: #e0eedd;
     border-left: solid 4px #679060;
     line-height: 18px;
     mc-auto-number-format: '{b}Note: {/b}';
     overflow: hidden;
     padding: 12px;
}

.custom-tooltip {
  background-color: "#ffffff";

  background: linear-gradient(130deg, #72a569 10%, #8cb169 25%, #799dae 61%, #a8bc68 81%, #e6cf60 100%);
  border: 2px solid #8cb169; /* Add a border for emphasis */
  padding: 10px; /* Add some padding */
  color: white; /* Set the text color */
  font-size: 14px; /* Set the font size */
  border-radius: 5px; /* Round the corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15); /* Add a subtle shadow */
}

.custom-tooltip::after {
  border-top-color: #72a569; /* Change arrow color to match gradient */
}



/* Container to center video and add background */
.video-container {
  position: relative;
  width: 100%;
  /* height: 70vh; */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #000;
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3); /* Soft shadow */
}

/* Video styling with rounded corners */
.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px; /* Matches the container’s rounded corners */
}

/* Overlay styling remains the same */
.overlay {
  position: absolute;
  align-items:self-start;
  z-index: 2;
  text-align: center;
  color: #fff;
}

.overlay h1 {
  font-size: 2.5em;
  margin: 0;
  font-weight: 700;
  letter-spacing: 1.5px;
  color: #ffdd57;
  text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.8);
}

.overlay p {
  font-size: 1.2em;
  font-weight: 300;
  color: #f0f0f0;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}


/* Progress Circular BAr Styles */
.CircularProgressbar .CircularProgressbar-text{
  font-family: 'Nunito', sans-serif; /* Replace with your desired font */
  font-size: 12px; /* Adjust font size */
  font-weight: bolder; /* Adjust font weight */
  fill: #72a569 !important; /* Text color */
}

/*Investment Info *?
/* Container for the cards */
.investment-info-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  padding: 10px;
}

/* Individual card styles */
.card {
  width: 180px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border-color: #dee2e6;
  text-align: center;
  position: relative;
}





.card-image {
  width:189px;
  padding: 20px;
  background-color: rgba(114,165,105,0.1);
  background-image: url('../../../public/assets/images/background/portfoliocard.png'); /* Add your image path here */
  background-size: cover; /* Ensures the image covers the entire card */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border-color: #dee2e6;
  text-align: center;
  position: relative;
}
.card-image-lg {
  width:280px;
  padding: 20px;
  background-color: rgba(114,165,105,0.1);
  background-image: url('../../../public/assets/images/background/portfoliocard.png'); /* Add your image path here */
  background-size: cover; /* Ensures the image covers the entire card */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border-color: #dee2e6;
  text-align: center;
  position: relative;
}
.card-image-xl {
  width: 100%; /* Set width to 100% to take up the parent container's width */
  padding: 20px;
  background-color: rgba(114, 165, 105, 0.1);
  background-image: url('../../../public/assets/images/background/portfoliocard.png'); /* Add your image path here */
  background-size: cover; /* Ensures the image covers the entire card */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border-color: #dee2e6;
  text-align: center;
  position: relative; /* Required for absolute positioning of child elements */
}

/* Value text style */
.value {
  font-size: 20px;
  font-weight: bold;
  color: #72a569; /* Green color */
  margin: 0;
}

/* Icon styles */
.card-image .icon {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #679060; /* Icon color */
  font-size: 30px; /* Adjust size as needed */
}

/* Icon styles */
.card-image-lg .icon {
  position: absolute;
  top: 15px;
  left: 15px;
  color: #679060; /* Icon color */
  font-size: 35px; /* Adjust size as needed */
}

/* Icon styles */
.card-image-xl .icon {
  position: absolute; /* Absolute positioning relative to parent */
  top: 15px; /* Position from the top */
  left: 15px; /* Align to the left */
  color: #679060; /* Icon color */
  font-size: 35px; /* Adjust size as needed */
}

/* New class for the bottom-left background */
.bottom-left-background {
  position: absolute; /* Position absolutely within the card */
  bottom: 5px; /* Align to the bottom */
  right: 15px; /* Align to the left */
  width: 100px; /* Set width as needed */
  height: 100px; /* Set height as needed */
  background-image: url('../../../public/assets/images/seal/seal-palette.png'); /* Add your second image path here */
  background-size: contain; /* Adjust as needed */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent repeating */
  z-index: 0; /* Ensures it’s below the other content */
  
}

/* Additional styles for the content */
.card-image-xl .content {
  position: relative; /* Ensure content appears above the background */
  z-index: 1; /* Keep content above the background */
  /* Additional styles for content can be added here */
}
/* Label text style */
.label {
  font-size: 14px;
  color: #484848;
  margin: 8px 0 0 0;
}

/* Additional ROI text */
.additional {
  font-size: 12px;
  color: #72a569;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #e8f5e9; /* Light green background */
  padding: 2px 5px;
  border-radius: 5px;
}
