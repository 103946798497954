.dashboard_navigationbar {
  margin-bottom: 30px;
}
.dashboard_navigationbar .dropbtn {
  background-color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  display: block;
  height: 70px;
  margin-bottom: 30px;
  outline: none;
  padding: 20px 30px;
  position: relative;
  text-align: left;
  width: 100%;
}
.dashboard_navigationbar .dropbtn:hover,
.dashboard_navigationbar .dropbtn:focus {
  background-color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
  outline: none;
}
.dashboard_navigationbar .dropdown {
  position: relative;
}
.dashboard_navigationbar .dropdown-content {
  box-shadow: none;
  display: none;
  height: auto;
  min-width: 160px;
  overflow: auto;
  position: absolute;
  top: 0;
}
.dashboard_navigationbar .dropdown-content a {
  color: black;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
}
.dashboard_navigationbar .dropdown-content li {
  height: 50px;
  line-height: 30px;
  padding-left: 0;
  position: relative;
}
.dashboard_navigationbar .dropdown-content li a {
  font-size: 16px;
}
.dashboard_navigationbar .dropdown-content li a span {
  font-size: 23px;
  padding-right: 10px;
}
.dashboard_navigationbar .dropdown-content li.active {
  background-color: #ffffff;
}
.dashboard_navigationbar .dropdown-content li.active:before {
  background-color: var(--color-primary);
  bottom: 0;
  content: "";
  height: 50px;
  position: absolute;
  right: 0px;
  top: 0;
  width: 2px;
}
.dashboard_navigationbar .dropdown-content li.active a {
  color: var(--color-primary);
}
.dashboard_navigationbar .dropdown a:hover {
  color: #004a97;
}
.dashboard_navigationbar .show {
  display: block;
  -webkit-animation: fadein 2s;
  -moz-animation: fadein 2s;
  -ms-animation: fadein 2s;
  -o-animation: fadein 2s;
  animation: fadein 1s;
  overflow: hidden;
  padding: 15px 0;
  position: relative;
  min-height: auto;
  min-width: auto;
  width: 100%;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
